// import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import Story from './Story';
import TimeBar from './TimeBar';
// import btb_white from '../assets/img/beatthebuffalo.png';
// import btb_rev from '../assets/img/beatthebuffalo-rev.png';
import logo from '../assets/HEB-branding-logos.png';

const daynames = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
const hrs = ["5 am","6 am","7 am","8 am","9 am","10 am","11 am","12 pm","1 pm","2 pm","3 pm","4 pm","5 pm","6 pm","7 pm","8 pm","9 pm","10 pm","11 pm","12 am"];

const sched = [
    { day: "Sunday",
        date: "May 23, 1943",
        starttime: 5,
        endtime: 8,
        title: "Night Patrol",
        war: "Second World War",
        image: "patrol.jpg",
        desc: "At this time, we’ll slip into No Man’s Land – the unheld stretch between the opposing trenches, extending for hundreds of metres – to gather intelligence. Patrol cautiously, observe enemy positions and assess their fortifications.",
        hero: "meeting-with-the-past-patrol-optimized.jpg" 
    },
    { day: "Sunday",
        date: "Mar 17, 1991",
        starttime: 9,
        endtime: 12,
        title: "Mine Disarming",
        war: "Persian Gulf",
        image: "minedisarming.jpg",
        desc: "As a part of Operation Magnolia, our task force of 23 Canadians will be spending most of our day detecting and destroying unexploded munitions and landmines.",
        hero: "meeting-with-the-past-mine-disarming.jpg" 
    },
    { day: "Sunday",
        date: "May 08, 2011",
        starttime: 12.5,
        endtime: 20,
        title: "Training",
        war: "Afghanistan",
        image: "training.png",
        desc: "As part of our continued effort to keep sustainable peace in the country, we’ll be starting training with a new group from the Afghan National Defense and Security Forces.",
        hero: "_x34_xoc33.jpg" 
    },
    { day: "Monday",
        date: "May 03, 1915",
        starttime: 5.5,
        endtime: 9,
        title: "Breakfast",
        war: "First World War",
        image: "breakfast.jpg",
        desc: "As morning breaks, the day begins just like every other: half an hour before dawn with an hour of “stand-to arms,” preparing for any potential enemy attack in the dim light. Once stand-down is called, we move on to our tasks: cleaning rifles and trenches, inspections, and finally, breakfast. Remember, an army marches on its stomach, and for us, breakfast is simple and often bland.<br/><br/>Here's a list of what’s to be expected:<li>dry biscuits</li><li>water</li><li>tea</li><li>oatmeal or porridge (if available)</li>Don’t expect fancy flavours; it’s about fuelling up for the long, tough day ahead.",
        hero: "meeting-with-the-past-brakfast-optimized.jpg" 
    },
    { day: "Monday",
        date: "Jan 22, 1945",
        starttime: 10,
        endtime: 18,
        title: "Spies",
        war: "Second World War",
        image: "spies.png",
        desc: "We’ll be meeting at our office in Toronto to transcribe, filter and decode Japanese and German messages from the enemy. Most attendants in the invite list are from the Women’s Royal Canadian Naval Services, but your presence is mandatory even if you’re not a part of this organization.",
        hero: "meeting-with-the-past-spies-optimized.jpg" 
    },
    { day: "Tuesday",
        date: "Jun 06, 1944",
        starttime: 7,
        endtime: 24,
        title: "Disembarking on D-Day",
        war: "Second World War",
        image: "dday.png",
        video: "p4zzgMR0nk0"
    },
    { day: "Wednesday",
        date: "Oct 01, 1952",
        starttime: 5,
        endtime: 7.5,
        title: "Wake Up",
        war: "Korean War",
        image: "wakeup.jpg",
        desc: "The wake-up call for this day will be at 05:00 a.m. on this day. However, other circumstances might cause this schedule to shift, like being too close to an enemy troop or threatened by an attack.",
        hero: "meeting-with-the-past-wake-up.jpg" 
    },
    { day: "Wednesday",
        date: "Jun 13, 1916",
        starttime: 7.5,
        endtime: 10.5,
        title: "Cleaning Latrines",
        war: "First World War",
        image: "cleaninglatrines.jpg",
        desc: "We’ll be doing most of the day’s tasks in the trenches underground, starting with the most unpleasant one: cleaning the latrines. Please remember that enemy snipers could be anywhere. The G98, the German Army standard rifle, for example, could reach as far as 600 metres.",
        hero: "_x34_xoc33.jpg" 
    },
    { day: "Wednesday",
        date: "Jan 06, 1915",
        starttime: 10.5,
        endtime: 18,
        title: "Morphine Rounds",
        war: "First World War",
        image: "morphine.png",
        desc: "We’ll be meeting at the Casualty Clearing Station to receive the wounded brought by the ambulances from the battlefield. After initial assessments, be expected to assist in surgery and watch for secondary infections. You might also be asked to help in cases of dysentery, trench fevers, and pneumonia. Conditions are not ideal, so look out for rats and fleas near the patients.",
        hero: "meeting-with-the-past-morphine-rounds-optimized.jpg" 
    },
    { day: "Thursday",
        date: "Feb 19, 1942",
        starttime: 5,
        endtime: 9,
        title: "Code Talking",
        war: "Second World War",
        image: "codetalking.jpg",
        desc: "During this time, we’ll translate sensitive audio messages into Cree so they won’t be understood if intercepted. A second Cree-speaking group will be on the other end of the line, making sure the messages are being translated back into English.",
        hero: "meeting-with-the-past-code-talking.jpg" 
    },
    { day: "Thursday",
        date: "Feb 22, 1951",
        starttime: 9,
        endtime: 11.5,
        title: "Frostbite",
        war: "Korean War",
        image: "frostbite.jpg",
        desc: "During this time, we’ll be moving to the front of the battlefield as part of Operation Killer. The cold is expected to be severe, so be aware of signs of frostbite and freezing of the lubricant in your weapons.",
        hero: "meeting-with-the-past-frostbite-optimized.jpg" 
    },
    { day: "Thursday",
        date: "Oct 11, 1917",
        starttime: 11.5,
        endtime: 16,
        title: "Rum Rations",
        war: "First World War",
        image: "rum.png",
        desc: "Join us as we partake in our daily rum ration – both for warmth and for courage. The beverage will come in clay jars marked with SRD, and the two-ounce ration per person must be drunk at once. Please check with your brigade commanders to see if your presence is allowed. The rum must be consumed in the presence of a disposing officer to prevent hoarding.",
        hero: "_x34_xoc33.jpg" 
    },
    { day: "Friday",
        date: "Sep 10, 1916",
        starttime: 6.5,
        endtime: 24,
        title: "Battle of Somme",
        war: "",
        image: "somme.png",
        video: "p4zzgMR0nk0"
    },
    { day: "Saturday",
        date: "Feb 10, 1945",
        starttime: 6,
        endtime: 24,
        title: "Liberation of the Netherlands",
        war: "",
        image: "netherlands.png",
        video: "p4zzgMR0nk0"
    },
]

export default function Calendar(){

    // const navigate = useNavigate();

    const [ thisStory, setStory ] = useState(sched.find(st=>window.location.hash==='#'+st.date.replace(', ','-').replace(' ','-')));
    const [ thisRow, setRow ] = useState( (new Date()).getDay() );
    const [ thisCol, setCol ] = useState(null);
    const [ showStory, setShowStory ] = useState(window.location.hash===''?false:true);

    function clickStory(d) {
        const s = sched.find(st=>st.date===d);
        setStory(s);
        setShowStory(true);
    }
    function hoverStory(d,e) {
        setRow(d);
        setCol(e);
    }
    function onScroll(e){
        const grid = e.target;
        const swidth = grid.scrollWidth;
        const sleft = grid.scrollLeft;
        const sx = Math.round(7 * ( sleft / swidth ));
        setRow(sx);
    }
    function clickDay(i){
        setRow(i);
        const nCols = sched.filter( entry=> entry.day===daynames[i] ).length;
        if (thisCol>nCols-1) setCol(nCols-1);
        const grid = document.querySelector('.grid-col');
        const gw = grid.getBoundingClientRect().width;
        const gs = grid.scrollWidth;
        if (gs>gw){
            const gcol = gs/7;
            grid.scrollTo({left:i*gcol,behavior:'smooth'});
        }
        
    }

    useEffect(() => {
        function handleKeyDown(e) {
            if (showStory) return;  
            switch (e.keyCode){
                case 37: // left
                    if (thisRow>0) {
                        setRow(thisRow-1);
                        const nCols = sched.filter( entry=> entry.day===daynames[thisRow-1] ).length;
                        if (thisCol>nCols-1) setCol(nCols-1);
                    }
                    break;
                case 38: // up
                    if (thisCol>0) {
                        setCol(thisCol-1);
                        e.preventDefault();
                    }
                    break;
                case 39: // right
                    if (thisRow<6) {
                        setRow(thisRow+1);
                        const nCols = sched.filter( entry=> entry.day===daynames[thisRow+1] ).length;
                        if (thisCol>nCols-1) setCol(nCols-1);
                    }
                    break;
                case 40: // down
                    const nCols = sched.filter( entry=> entry.day===daynames[thisRow] ).length;
                    if (thisCol<nCols-1) {
                        setCol(thisCol+1);
                        e.preventDefault();
                    }
                    break;
                case 0:
                case 32: 
                case 13: // spacebar/enter  
                console.log('spacebar');    
                    setStory(sched.filter( entry=> entry.day===daynames[thisRow] )[thisCol]);
                    setShowStory(true);
                    e.preventDefault();
                    break;
    
            }
        }
    
        document.addEventListener('keydown', handleKeyDown);
    
        // Don't forget to clean up
        return function cleanup() {
          document.removeEventListener('keydown', handleKeyDown);
        }
      }, [showStory,thisRow,thisCol]);

      // onchange showstory
      useEffect(()=>{
        if (showStory===false){
            window.location.hash = '';
        }
      },[showStory]);

      useEffect(()=>{
        const grid = document.querySelector('.grid-col');
        const gw = grid.getBoundingClientRect().width;
        const gs = grid.scrollWidth;
        if (gs>gw){
            const gcol = gs/7;
            grid.scrollTo({left:thisRow*gcol,behavior:'smooth'});
        }
      },[]);

    return (
        <>
            <main>
                <div className="container intro">
                    <p>With all the meetings, appointments and deadlines, our schedules can feel overwhelming. Now, imagine what the day of a soldier looked like. From meetings with the enemy to calls for help, every hour could be dangerous or even life-threatening. Click on the time slots in the calendar to learn more about the lives of those who have done so much for our country.</p>
                    <img src={logo} alt="The Legion National Foundation | With the support of Home Equity Bank" className="title-logo" />
                </div>
                <div className="container">
                    <div className="heading-row">
                        {daynames.map((d,i)=><li key={i} className={i===thisRow?'selected':''} onClick={()=>clickDay(i)}>{d.substring(0,2)}<span>{d.substring(2,3)}</span></li>)}
                    </div>
                    <div className="row">
                        <div className="hour-col">
                            {hrs.map((h,i)=><li key={i}>{h}</li>)}
                        </div>
                        <div className="grid-col" onScroll={onScroll}>
                            <div className="week-container">
                                {daynames.map((d,i)=><div key={i} className={`week-col ${i===thisRow?'selected':''}`}>
                                    {sched.filter( entry=> entry.day===d ).map( (entry,e)=>
                                        <div className={`calendar-entry ${entry.video?'has-video':''} ${thisRow===i&&thisCol===e?'current':''}`} key={`${d}-${e}`} 
                                            onClick={()=>clickStory(entry.date)} onMouseOver={()=>hoverStory(i,e)}
                                            onMouseOut={()=>hoverStory(null,null)}
                                            tabIndex={0} onFocus={()=>hoverStory(i,e)}
                                            style={{ "--start-pos":entry.starttime-5, "--length":entry.endtime-entry.starttime, "--bgpath":'url(/img/'+entry.image+')' }}>
                                            <span>{entry.date}</span>
                                            <h4>{entry.title}</h4>
                                        </div>
                                    )}
                                </div>)}
                            </div>
                            <TimeBar currentDay={thisRow} />
                        </div>
                    </div>
                </div>
            </main>
            {showStory && <Story content={thisStory} onClose={()=>setShowStory(false)} />}
        </>
    );
}