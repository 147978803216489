import { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import btb_white from '../assets/img/beatthebuffalo.png';
// import btb_rev from '../assets/img/beatthebuffalo-rev.png';

export default function Footer(){

    const [ linkCopied, setLinkCopied ] = useState(false);

    // const navigate = useNavigate();
    function copyLink(){
        navigator.clipboard.writeText(document.location.href);
        setLinkCopied(true);
        setTimeout(()=>setLinkCopied(false),3000);
    }

    return (
        <footer>
            <div className="container">
                <div className="donate-cell">
                    <h3>DONATE A DIGITAL POPPY NOW.<br/>HONOUR THEIR SACRIFICE.</h3>
                    <a className="button" href="https://mypoppy.ca/donation.php" target="_blank" rel="noreferrer">Donate Now</a>
                </div>
                <div className="social-cell">
                    <a className="icon fb" href="https://www.facebook.com/LNFCanada/" target="_blank" rel="noreferrer">Facebook</a>
                    <a className="icon tw" href="https://twitter.com/LNFCanada" target="_blank" rel="noreferrer">X</a>
                    <button className={`icon link ${linkCopied?'copied':''}`} onClick={copyLink}>Copy Link</button>
                </div>
            </div>
            <div className="container legal">
                <small>You are not alone. If you are a Veteran in crisis or are concerned about one, please call the Veterans Affairs Canada support line at 1-800-268-7708, available 24/7. Your call is free and confidential. Support is available to Veterans, former RCMP members, their family members, and caregivers. </small>
                <small>Some rights reserved. Imagery owned and provided by The Legion Magazine have been altered for the purpose of this campaign.<span>© 2024, HomeEquity Bank</span></small>
            </div>
        </footer>
    );
}