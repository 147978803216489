// import { useNavigate } from "react-router-dom";
// import btb_rev from '../assets/img/beatthebuffalo-rev.png';
import title from '../assets/MWTP-logo.png';

export default function Header(){

    // const navigate = useNavigate();

    return (
        <header>
            <div className="container">
            <img src={title} alt="Meeting with the Past" className='logo' />
            <a className="button" href="https://mypoppy.ca/donation.php" target="_blank" rel="noreferrer">Donate Now</a>
            </div>
        </header>
    );
}