import Modal from 'react-modal';
import { useState, useEffect } from 'react';
import mwtp_logo from './../assets/MWTP-horizontal.png';

export default function Story({content,onClose}){

    
    

    function militaryTime(n){
        let ts = n*100;
        if (ts%100===50) {
            ts -= 20; 
        }
        if (ts<1000){
            return '0'+ts;
        } else {
            return ts;
        }
    }

    const [ linkCopied, setLinkCopied ] = useState(false);

    // const navigate = useNavigate();
    function copyLink(){
        navigator.clipboard.writeText(document.location.href);
        setLinkCopied(true);
        setTimeout(()=>setLinkCopied(false),3000);
    }
    function shareFB(){
        const siteDomain = document.location.href.replace('#','%23'); 
        const shareLink = `https://www.facebook.com/sharer/sharer.php?u=${siteDomain}`
        window.open(shareLink, "Social", "width=500,height=500");
    }
    function shareTW(){
        const siteDomain = document.location.href.replace('#','%23'); 
        const shareLink = `https://twitter.com/intent/tweet?url=${siteDomain}&text=Have%20a%20meeting%20with%20the%20past%20and%20learn%20more%20about%20the%20dangerous%20day-to-day%20lives%20of%20the%20Canadian%20soldiers%20who%20have%20sacrificed%20so%20much%20for%20our%20country.`
        window.open(shareLink, "Social", "width=500,height=500");
    }

    function onKeyDown(e){
        if (e.keyCode===0 || e.keyCode===13 || e.keyCode===32) {
            onClose();
        }
        e.preventDefault();
        e.stopPropagation();
    }
    
    useEffect(()=>{
        Modal.setAppElement('main');
        window.location.hash = '#'+content.date.replace(', ','-').replace(' ','-');
    },[]);

    return (
        <Modal
            isOpen={true}
            onRequestClose={onClose}
            shouldCloseOnOverlayClick={true}
            className="Modal"
            overlayClassName="Overlay">
                <button onClick={onClose} onKeyDown={onKeyDown} className="close-btn">Close</button>
                <div className="story_body" data-has-video={content.video?true:false}>
                    {content.video
                        ? <>
                            <span className="story_time">{militaryTime(content.starttime)} {content.war?'/':''} {content.war} </span>
                            <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${content.video}?t=179s&autoplay=1&controls=0`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </>
                        : <>
                            <div className="story_image">
                                <img src={`/img/${content.hero}`} alt={content.title} />
                                <span className="story_time">{militaryTime(content.starttime)} {content.war?'/':''} {content.war} </span>
                            </div>
                            <div className="story_text">
                                <em>{content.date}</em>
                                <h2>{content.title}</h2>
                                <p dangerouslySetInnerHTML={{__html:content.desc}}></p>
                                <div className="social-cell">
                                    <p>Share:</p>
                                    <button className="icon fb" onClick={shareFB}>Facebook</button>
                                    <button className="icon tw" onClick={shareTW}>X</button>
                                    <button className={`icon link ${linkCopied?'copied':''}`} onClick={copyLink}>Copy Link</button>
                                </div>
                            </div>
                        </>
                    }
                </div>
                <div className="story_footer">
                    {content.video
                        ? 
                        <div className="social-cell">
                            <span>Share:</span>
                            <button className="icon fb" onClick={shareFB}>Facebook</button>
                            <button className="icon tw" onClick={shareTW}>X</button>
                            <button className={`icon link ${linkCopied?'copied':''}`} onClick={copyLink}>Copy Link</button>
                        </div>
                        :
                        <img src={mwtp_logo} alt="Meeting With The Past" />
                    }
                    <a className="button" href="https://mypoppy.ca/donation.php" target="_blank" rel="noreferrer">Donate Now</a>
                </div>
        </Modal>
    );
}
