import { useState, useEffect } from 'react';

export default function TimeBar({currentDay}){

    const [ time, setTime ] = useState( (new Date()).getHours() + ((new Date()).getMinutes()/60)-5 );

    const setRelativeTime = () => {
        const d = new Date();
        setTime( d.getHours() + (d.getMinutes()/60)-5 );
    }

    // when day changes
    useEffect(()=>{

    },[currentDay]);

    // onload
    useEffect(()=>{
        const interval = setInterval(setRelativeTime, 5*60*1000); // refresh every 5 minutes

        return () => clearInterval(interval);
    },[]);

    return (
        time>=0 
         ? <div className="time-indicator" style={{ "--current-day":currentDay, top:(time/.19)+'%' }}></div>
         : <></>
    )
}
